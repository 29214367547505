import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../Component/Navbar';
import "../Css/Home.css"
import { IoConstructOutline } from "react-icons/io5";
import { PiFireTruckBold } from "react-icons/pi";
import { MdPlumbing } from "react-icons/md";
import { GiElectricalResistance } from "react-icons/gi";
import { GiSkills } from "react-icons/gi";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const commentsData = [
    {
        id: 1,
        photo: 'https://randomuser.me/api/portraits/men/65.jpg',
        name: 'Rohan Mehta',
        profession: 'Electrical Engineer',
        comment: 'elMech India Engineers handled the entire electrical setup for our industrial project. Their expertise and attention to detail were evident from day one, ensuring a smooth execution on-site.'
    },
    {
        id: 2,
        photo: 'https://randomuser.me/api/portraits/women/68.jpg',
        name: 'Priya Sharma',
        profession: 'Civil Engineer',
        comment: 'I was impressed by the quality of the firefighting systems elMech India Engineers installed in our commercial building. The team was efficient and kept us informed throughout the process.'
    },
    {
        id: 3,
        photo: 'https://randomuser.me/api/portraits/men/73.jpg',
        name: 'Suresh Verma',
        profession: 'Property Contractor',
        comment: 'We collaborated with elMech India Engineers for electrical and plumbing services in a large residential project. Their technical expertise and commitment to timelines were exceptional.'
    },
    {
        id: 4,
        photo: 'https://randomuser.me/api/portraits/women/50.jpg',
        name: 'Anita Iyer',
        profession: 'Bank Manager',
        comment: 'The electrical design for our new bank branch was flawlessly executed by elMech. They ensured the highest safety standards and completed the work ahead of schedule.'
    },
    {
        id: 5,
        photo: 'https://randomuser.me/api/portraits/men/77.jpg',
        name: 'Amit Desai',
        profession: 'Construction Consultant',
        comment: 'elMech India Engineers provided excellent support during the planning phase of our project. Their design solutions were both innovative and practical, helping us stay within budget.'
    },
    {
        id: 6,
        photo: 'https://randomuser.me/api/portraits/women/35.jpg',
        name: 'Neha Kapoor',
        profession: 'Architect',
        comment: 'I highly recommend elMech India Engineers for their professionalism and expertise in the fire safety systems they implemented in our IT park project.'
    },
    {
        id: 7,
        photo: 'https://randomuser.me/api/portraits/men/55.jpg',
        name: 'Rajesh Kumar',
        profession: 'HVAC Specialist',
        comment: 'Working with elMech India Engineers was a smooth experience. They delivered top-notch HVAC solutions that met the complex requirements of our hospital project.'
    },
    {
        id: 8,
        photo: 'https://randomuser.me/api/portraits/women/47.jpg',
        name: 'Swati Joshi',
        profession: 'Hospital Administrator',
        comment: 'elMech played a crucial role in the electrical and firefighting designs of our new hospital wing. Their team was responsive and attentive to every detail.'
    },
    {
        id: 9,
        photo: 'https://randomuser.me/api/portraits/men/43.jpg',
        name: 'Vikas Patel',
        profession: 'Real Estate Developer',
        comment: 'elMech India Engineers delivered exceptional services in electrical and plumbing systems for our high-rise residential complex. Their technical expertise ensured timely project completion.'
    },
    {
        id: 10,
        photo: 'https://randomuser.me/api/portraits/women/27.jpg',
        name: 'Kavita Rao',
        profession: 'Interior Designer',
        comment: 'The electrical installations by elMech in our luxury hotel project were flawless. Their attention to detail and willingness to adapt to design changes were highly appreciated.'
    }
];

const Home = () => {
    const words = ["Dreams", "Future", "Legacy", "Success", "Reality", "Innovations", "Possibilities"];
    const [currentWord, setCurrentWord] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentWord((prevWord) => (prevWord + 1) % words.length);
        }, 1000); // Change word every second

        return () => clearInterval(interval); // Clean up the interval on unmount
    }, [words.length]);



    const [currentIndex, setCurrentIndex] = useState(0);


    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 2) % commentsData.length);
        }, 2000); // Change every 2 seconds

        return () => clearInterval(interval); 
    }, []);

    const visibleComments = commentsData.slice(currentIndex, currentIndex + 2);

    const images = [
        "https://images.pexels.com/photos/12255/pexels-photo-12255.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",

        "https://images.pexels.com/photos/176342/pexels-photo-176342.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        "https://images.unsplash.com/photo-1503387762-592deb58ef4e?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

        "https://images.unsplash.com/photo-1482731215275-a1f151646268?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1503387762-592deb58ef4e?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        // "https://https://images.pexels.com/photos/2449785/pexels-photo-2449785.jpeg?auto=compress&cs=tinysrgb&w=600"
    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [images.length]);


    const animateNumbers = (targetElements) => {
        targetElements.forEach((el) => {
            let count = 0;
            const target = parseInt(el.getAttribute("data-target"), 10);
            const increment = target / 100;
            const interval = setInterval(() => {
                count += increment;
                if (count >= target) {
                    el.textContent = target;
                    clearInterval(interval);
                } else {
                    el.textContent = Math.floor(count);
                }
            }, 20);
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const numberElements = document.querySelectorAll(".boxes p");
                    animateNumbers(numberElements); // Trigger the animation once in view
                    observer.disconnect(); // Only trigger once
                }
            });
        }, { threshold: 0.5 }); // Start animation when 50% of the section is visible

        // Observe the '.reviewOfWork' section
        observer.observe(document.querySelector('.reviewOfWork'));
    }, []);

    const aboutUsRef = useRef(null);
    const servicesRef = useRef(null);
    const contactRef = useRef(null);

    const handleAboutUsClick = () => {
        aboutUsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    const handleServicesClick = () => {
        servicesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const handleContactClick = () => {
        contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };


    return <div>
        <div className="mainHome">
            <div className="homeSlideView" style={{
                backgroundImage: `url(${images[currentImageIndex]})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                transition: 'background-image 1s ease-in-out' // Optional smooth transition
            }}>
                <div className="navBarAtHome">
                    <Navbar onAboutUsClick={handleAboutUsClick}   onServicesClick={handleServicesClick} 
                    onContactClick={handleContactClick} />
                </div>
                <div className="firstSlide">
                <div className="FirstSlideTagLine">
                        <span>Your Vision, Our Blueprint</span>
                        <p>We are not Constructing, We <br />
                            <span className="word-roller">
                                <span className="roller-word">{words[currentWord]}</span>
                            </span>
                        </p>
                    </div>                  
                </div>

            </div>





            <div className="aboutUs" ref={aboutUsRef}>
                <div className="leftside">
                    <span className='AboutUs'>ABOUT US</span>
                    <span>Transforming spaces creating memories</span>
                    <p>elMech India Engineers, founded by Mr. Anil K. Shakya with over 12 years of experience, specializes in Electrical, Firefighting, and Building Services. The company has successfully executed more than 50 projects across various sectors, including IT buildings, hotels, hospitals, and infrastructure for both private and government clients. With a commitment to delivering quality from conceptualization to completion, elMech India Engineers ensures international standards and customer satisfaction on every project.</p>
                </div>
                <div className="rightSide">
                    <img src="https://themedox.com/demo/steelcity/assets/img/banner/banner-image.png" alt="" />
                </div>
            </div>
            <div className="midPromoSlide">

                <img src="https://themes247.net/html5/construction-template/demo/assets/img/bg-section-2.jpg" alt="" />


                {/* <img src="https://t4.ftcdn.net/jpg/02/69/66/55/240_F_269665555_EUTbghVSidprZtdsV94mhuq4vObgkXGo.jpg" alt="" /> */}
                <p>WE ARE BUILDING THE FUTURE AND <br /> RESTORING THE PAST</p>
            </div>







            <div className="ServicesSlide"  ref={servicesRef}>
                <div className="ServicesBoxes ServicesBoxesOne">
                    <div className="ServiceBox FirstServiceBox">
                        <p className="TopRow">
                            <span className='ServiceTopRowIcon'><IoConstructOutline /></span>
                            <span className='ServiceTopRowP'>WHAT WE DO</span>

                        </p>
                        <p className="bottomRow OtherServiceBox">
                            <span>Oue services that we provided</span>
                            <p>We craft unique digital experiences. With more than 7 years of expertise we design and code clean</p>
                        </p>
                    </div>
                    <div className="ServiceBox OtherServiceBox">


                        <div className="logo" ><i class="fa-solid fa-screwdriver-wrench"></i></div>
                        <p className='OtherServiceBoxInfoTopRow'>Costruction Consultant</p>
                        <p className='OtherServiceBoxInfoBottomRow'>Get expert guidance for your project from start to finish, ensuring smooth and successful results.</p>
                    </div>
                    <div className="ServiceBox OtherServiceBox">
                        <div className="logo"><PiFireTruckBold /></div>
                        <p className='OtherServiceBoxInfoTopRow'>Fire Fighting System</p>
                        <p className='OtherServiceBoxInfoBottomRow'>Protect your property with advanced fire safety systems that are reliable and ready when you need them.</p>
                    </div>
                    <div className="ServiceBox OtherServiceBox">
                        <div className="logo"><MdPlumbing /></div>
                        <p className='OtherServiceBoxInfoTopRow'>Plumbing Services</p>
                        <p className='OtherServiceBoxInfoBottomRow'>Count on us for fast, dependable plumbing solutions that keep your systems running smoothly.</p>
                    </div>
                    <div className="ServiceBox OtherServiceBox">
                        <div className="logo"><i class="fa-solid fa-trowel-bricks"></i></div>
                        <p className='OtherServiceBoxInfoTopRow'>Reconstruction Services</p>
                        <p className='OtherServiceBoxInfoBottomRow'>We restore and rebuild with care, bringing new life to your property with quality and precision.</p>
                    </div>
                    <div className="ServiceBox OtherServiceBox">
                        <div className="logo"><GiElectricalResistance /></div>
                        <p className='OtherServiceBoxInfoTopRow'>Electrical System</p>

                        {/* <p className='OtherServiceBoxInfoTopRow'>Architectural design</p> */}
                        <p className='OtherServiceBoxInfoBottomRow'>Safe, efficient electrical systems designed to power your building reliably and effectively.</p>
                    </div>
                </div>
            </div>



            <div className="reviewOfWork">
                <div className="boxes">
                    <div>
                        <div className="numberWrapper">
                            <p data-target="50" className="number">1</p>
                            <span className="plusSign">+</span>
                        </div>
                        <span>Happy Customers</span>
                    </div>
                </div>
                <div className="boxes">
                    <div>
                        <div className="numberWrapper">
                            <p data-target="62" className="number">1</p>
                            <span className="plusSign">+</span>
                        </div>
                        <span>Completed Projects</span>
                    </div>
                </div>
                <div className="boxes">
                    <div>
                        <div className="numberWrapper">
                            <p data-target="19" className="number">1</p>
                            <span className="plusSign">+</span>
                        </div>
                        <span>Team Members</span>
                    </div>
                </div>
                <div className="boxes">
                    <div>
                        <div className="numberWrapper">
                            <p data-target="12" className="number">1</p>
                            <span className="plusSign">+</span>
                        </div>
                        <span>Years of Working</span>
                    </div>
                </div>
            </div>


            <div className="WhyUs">
                <div className="top">
                    <span>WHY CHOOSE US</span>
                    <p>High Quality Innovative Design</p>
                </div>

                <div className="BottomOfWhyUs">
                    <div className="leftRowInWhyUs">
                        <div className="BoxOfWhyUs">
                            <div>
                                <span>24 / 7 Support</span>
                                <p>We're always here, ensuring around-the-clock assistance for every project need.</p>
                            </div>
                            <div className='WhyUsBoxPng'><span><i class="fa-solid fa-phone"></i></span></div>
                        </div>
                        <div className="BoxOfWhyUs">
                            <div>
                                <span>Latest Technology</span>
                                <p>Harnessing cutting-edge technology to bring your vision to life with precision.</p>
                            </div>
                            <div className='WhyUsBoxPng'><span><i class="fa-solid fa-chalkboard-user"></i></span></div>
                        </div>
                        <div className="BoxOfWhyUs">
                            <div>
                                <span>Skilled Team</span>
                                <p>Our expert team delivers exceptional craftsmanship, backed by years of experience.</p>
                            </div>
                            <div className='WhyUsBoxPng'><span><i class="fa-solid fa-people-group"></i></span></div>
                        </div>
                    </div>
                    <div className="MidRowInWhyUs">
                        <img src="https://wp.framerpeak.com/construz/wp-content/uploads/2024/07/why-choose.png" alt="" />
                    </div>
                    <div className="RightRowInWhyUs">
                        <div className="BoxOfWhyUs">
                            <div>
                                <span>Innovation</span>
                                <p>Pioneering creative solutions to build beyond the ordinary, with unmatched innovation.</p>
                            </div>
                            <div className='WhyUsBoxPng'><span><i class="fa-regular fa-lightbulb"></i></span></div>
                        </div>
                        <div className="BoxOfWhyUs">
                            <div>
                                <span>Affordable Price</span>
                                <p>Quality construction at a price that fits your budget, without compromise.</p>
                            </div>
                            <div className='WhyUsBoxPng'><span><i class="fa-solid fa-handshake"></i></span></div>
                        </div><div className="BoxOfWhyUs">
                            <div>


                                <span>Experience</span>
                                <p>Decades of expertise in turning dreams into reality, one project at a time.</p>
                            </div>
                            <div className='WhyUsBoxPng'><span><GiSkills /></span></div>
                        </div>

                    </div>
                </div>
            </div>



            <div className="commentsIntro">
                <h2>What Our Clients Say</h2>
                {/* <p>We take pride in delivering excellent services, and here’s what some of our satisfied clients have to say about their experience with us.</p> */}
            </div>


            <div className="commentsContainer">
                {visibleComments.map((comment) => (
                    <div className="commentBox" key={comment.id}>
                        <div className="commentHeader">
                            <img src={comment.photo} alt={`${comment.name}'s photo`} className="userPhoto" />
                            <div className="userInfo">
                                <h3>{comment.name}</h3>
                                <p>{comment.profession}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="commentContent">
                            {/* <FaQuoteLeft className="quoteIcon" /> */}
                            <p>{comment.comment}</p>
                        </div>
                    </div>
                ))}
            </div>


            <div className="contactPage" ref={contactRef}>
                <div className="leftSide">
                    {/* <img src="https://images.pexels.com/photos/6474500/pexels-photo-6474500.jpeg" alt="" /> */}
                    <img src="https://themedox.com/demo/steelcity/assets/img/messages/messages-image.png" alt="" />
                </div>

                <div className="contactForm">
                    <span>Talk with Us</span>
                    <p>Building with passion, precision, and pride</p>
                    <form>
                        <div className="inputRow">
                            <div className="inputBox inputboxTop">
                                <input type="text" required />
                                <label>Name</label>
                            </div>
                            <div className="inputBox inputboxTop">
                                <input type="email" required />
                                <label>Email</label>
                            </div>
                        </div>
                        <div className="inputRow">
                            <div className="inputBox inputboxTop">
                                <input type="number" required />
                                <label>Mobile Number</label>
                            </div>
                            <div className="inputBox inputboxTop">
                                <input type="text" required />
                                <label>Location</label>
                            </div>
                        </div>
                        <div className="inputBox inputBoxright">
                            <textarea required></textarea>
                            <label>Message</label>
                        </div>
                        <button type="submit">Send Message</button>
                    </form>
                </div>
            </div>








            {/* <img src="https://media.istockphoto.com/id/1183292030/photo/two-male-architects-celebrating-success.jpg?s=612x612&w=0&k=20&c=odixG87mi18486r__QCQZIUIvB8gX6lcHrCThXbXvB0=" alt="" /> */}

            {/* 
            <div className="SlideThree">


                <div className="ProjectSlide">
                    <span className='ProjectsHead'>Our Projects</span>


                    <div className="slidingBox">



                        <div className="Boxes">
                            <div className="images">
                                <img src="https://images.pexels.com/photos/8961029/pexels-photo-8961029.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
                            </div>
                            <div className="ProjectAbout">
                                <div className='ProjectLocation'>Goa, India</div>
                                <div className="ProjectName">Project Name</div>
                                <div className="ProjectInfo">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
                            </div>
                        </div>
                        <div className="Boxes">
                            <div className="images">
                                <img src="https://images.pexels.com/photos/8961029/pexels-photo-8961029.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
                            </div>
                            <div className="ProjectAbout">
                                <div className='ProjectLocation'>Goa, India</div>
                                <div className="ProjectName">Project Name</div>
                                <div className="ProjectInfo">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
                            </div>
                        </div>
                        <div className="Boxes">
                            <div className="images">
                                <img src="https://images.pexels.com/photos/8961029/pexels-photo-8961029.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
                            </div>
                            <div className="ProjectAbout">
                                <div className='ProjectLocation'>Goa, India</div>
                                <div className="ProjectName">Project Name</div>
                                <div className="ProjectInfo">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
                            </div>
                        </div>
                        <div className="Boxes">
                            <div className="images">
                                <img src="https://images.pexels.com/photos/8961029/pexels-photo-8961029.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
                            </div>
                            <div className="ProjectAbout">
                                <div className='ProjectLocation'>Goa, India</div>
                                <div className="ProjectName">Project Name</div>
                                <div className="ProjectInfo">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
                            </div>
                        </div>
                        <div className="Boxes">
                            <div className="images">
                                <img src="https://images.pexels.com/photos/8961029/pexels-photo-8961029.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
                            </div>
                            <div className="ProjectAbout">
                                <div className='ProjectLocation'>Goa, India</div>
                                <div className="ProjectName">Project Name</div>
                                <div className="ProjectInfo">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


 */}<div className="footer">
  {/* Top Section */}
  <div className="footerTop">
    {/* Company Name and Logo */}
    <div className="footerColumn">
      {/* <img src="company-logo.png" alt="Company Logo" className="footerLogo" /> */}
      <h3>elMech India Engineers</h3>
    {/* <p></p><br/ /> */}
      <p>CEO :</p>
      <p>Mr. Anil Kr. Shakya</p>
    </div>

    {/* Contact Us Column */}
    <div className="footerColumn">
      <h3>Contact Us</h3>
      <p>Phone: 08826155004</p>
      <p>Email: anil.elmech@gmail.com</p>
      <p>Address: Sector-92 ,Noida(UP) , India</p>
    </div>

    {/* Social Profiles Column */}
    <div className="footerColumn">
      <h3>Social Profiles</h3>
      <div className="socialIcons">
        <i className="fab fa-facebook-f"></i>
        <i className="fab fa-twitter"></i>
        <i className="fab fa-instagram"></i>
        <i className="fab fa-linkedin-in"></i>
      </div>
    </div>
  </div>

  {/* Bottom Section */}
  <div className="footerBottom">
    <div className="footerCopyright">
      &copy; 2024 elMech India Engineers. All Rights Reserved.
    </div>
    <div className="footerLinks">
      <a href="">Terms and Conditions</a>
      <a href="">Privacy Policy</a>
      <a href="">Contact Us</a>
    </div>
  </div>
</div>







        </div>
    </div>;
}

export default Home;