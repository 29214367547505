import { React, useState } from 'react';
import { FaBars } from 'react-icons/fa6';
import { ImCross } from 'react-icons/im';
import { GiCrossFlare } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import '../Css/Navbar.css';

const Navbar = ({onAboutUsClick, onServicesClick, onContactClick}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleButton = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="Header">
        <div className="logoSide a">
          <div className="companyIcon active zx">
            <GiCrossFlare />
          </div>
          <div className="companyName zx">elMech</div>
        </div>

        <div className="navbarMid">
          <ul className={`navbarMenu ${isOpen ? 'open' : ''}`}>
          <li className="navbarNames">
              <Link className="LinkTAG LinkTAGHome active" to={"/"}>Home</Link>
            </li>
            <li className="navbarNames">
              <Link className="LinkTAG" to={""}  onClick={onAboutUsClick}>About Us</Link>
            </li>
            <li className="navbarNames">
              <Link className="LinkTAG" to={""} onClick={onServicesClick}>Services</Link>
            </li>
            <li className="navbarNames">
              <Link className="LinkTAG" to={""} onClick={onContactClick}>Contact Us</Link>
            </li>
          </ul>
        </div>

        <div className="toggleButton">
          <button onClick={toggleButton}>
            {isOpen ? <ImCross /> : <FaBars />}
          </button>
        </div>
      </div>

      {/* For mobile */}
      <div className={`headerMobile ${isOpen ? 'open' : ''}`}>
        <div className="navbarMidMobile a">
          <ul>
            <li className="navbarNames">
              <Link className="LinkTAG LinkTAGHome active" to={"/"}>Home</Link>
            </li>
            <li className="navbarNames">
              <Link className="LinkTAG" to={""} onClick={onAboutUsClick}>About Us</Link>
            </li>
            <li className="navbarNames">
              <Link className="LinkTAG" to={""} onClick={onServicesClick}>Services</Link>
            </li>
            <li className="navbarNames">
              <Link className="LinkTAG" to={""} onClick={onContactClick}>Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
